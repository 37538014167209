export const invalidNameChars = /[^a-zA-Z\s]/g;
export const multipleSpaces = /\s+/g;
export const invalidAlphanumeric = /[^a-zA-Z0-9\s]/g;
export const passportNumberReg = /[^a-zA-Z0-9]/g;
export const specialFareReg = /[^a-zA-Z0-9]+/g;
export const emailReg = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/
export const leadingSpaces = /^\s+/;
export const leadingTrailingSpaces = /^\s*|\s*$/g;
export const onlyNumber = /^\d+$/;
export const numberWithDotValue = /[^0-9.]/g;
export const allowedCharactersRegex = /^(?![IQWT\s])[A-Z0-9\-\/\s]{1,20}$/;
export const invalidCharactersRegex = /[^a-zA-Z0-9\-\/\s]/;
export const disallowedFirstCharacterRegex = /^[IQWTiqwt\s]/;
export const isdCodeRegex = /\D/g;
export const aplhaNumericDash = /[^a-zA-Z0-9\s-]/g;
export const spaceRestrict = /(^\S*$)/;
export const passRestrict = /^(?=.{0,13}$).*/;
export const isdCodeReg = /^[0-9]*$/;
export const gstNoReg = /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/;
export const alphanumericSpecialChars = /^[ A-Za-z0-9_@./#&+-]*$/;
export const alphabetNameChars = /^[a-zA-Z][a-zA-Z\s]*$/;
export const phoneNoReg = /^[0-9-+()]*$/;
export const panCardReg = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;