import React, { Component } from 'react';

class NotificationBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentIndex: 0,
      isScrolling: false,
      isPaused: false,
    };
  }

  componentDidMount() {
    this.startScrolling();
  }

  componentWillUnmount() {
    clearInterval(this.timer);
    clearTimeout(this.pauseTimer);
  }

  startScrolling() {
    this.setState({ isScrolling: true, isPaused: false }, () => {
      this.timer = setInterval(() => {
        this.setState((prevState) => {
          if (prevState.isScrolling) {
            this.pauseTimer = setTimeout(() => {
              this.setState({ isScrolling: false, isPaused: true });
              setTimeout(() => {
                this.setState({
                  currentIndex: prevState.currentIndex === this.props.data.newsAlert.length - 1 ? 0 : prevState.currentIndex + 1,
                  isScrolling: true,
                  isPaused: false,
                });
              }, 100);
            }, 3000);
          }
          return prevState;
        });
      }, 100);
    });
  }

  render() {
    const { newsAlert, styles } = this.props.data;
    const { currentIndex, isScrolling, isPaused } = this.state;

    return (
      <div className={styles.container}>
        <div className={[styles['marquee-wrapper'], newsAlert.length == 1 ? styles['marquee-wrapper-single'] : ''].join(' ')}>
          {newsAlert.length > 1 ? 
          <>
          <div className={styles.counter}>
            {currentIndex + 1}/{newsAlert.length}
          </div>
          <div className={styles['marquee-container']}>
            {newsAlert.length > 0 && newsAlert.map((item, index) => (
              <div
                className={`${styles['marquee-text']} ${index === currentIndex ? styles['marquee-text-active'] : ''}`}
              >
                {item.description}
              </div>
            ))}
          </div>
          </> :
            <div className={styles['marquee-container']}>
              {newsAlert.length > 0 && newsAlert.map((item, index) => (
                <div
                  className={`${styles['marquee-text-single']}`}
                >
                  {item.description}
                </div>
              ))}
            </div>
          }
          <div
            className={styles['close-icon']}
            onClick={() => this.props.data.onClickNotification()}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
              <circle cx="10.5" cy="10" r="9.5" stroke="white"/>
              <path
                d="M14.3134 7.21298C14.3717 7.14844 14.4183 7.07123 14.4503 6.98585C14.4824 6.90047 14.4992 6.80863 14.5 6.7157C14.5007 6.62276 14.4853 6.53058 14.4546 6.44454C14.424 6.3585 14.3787 6.28033 14.3214 6.21458C14.2641 6.14883 14.1959 6.09682 14.1209 6.06158C14.0459 6.02635 13.9655 6.00859 13.8844 6.00936C13.8034 6.01012 13.7233 6.02939 13.6488 6.06603C13.5742 6.10267 13.5069 6.15596 13.4505 6.22278L11.0035 9.02726L8.55722 6.22278C8.50133 6.15401 8.43392 6.09885 8.35903 6.06059C8.28414 6.02234 8.20329 6.00177 8.12131 6.00011C8.03933 5.99845 7.95791 6.01574 7.88188 6.05094C7.80586 6.08615 7.7368 6.13855 7.67882 6.20501C7.62085 6.27148 7.57514 6.35065 7.54444 6.43781C7.51373 6.52496 7.49865 6.61832 7.5001 6.7123C7.50154 6.80628 7.51948 6.89897 7.55285 6.98483C7.58622 7.07069 7.63434 7.14797 7.69432 7.21205L10.1389 10.0175L7.69269 12.8219C7.58485 12.9546 7.52614 13.1301 7.52893 13.3115C7.53172 13.4928 7.59579 13.6658 7.70765 13.7941C7.81952 13.9223 7.97043 13.9958 8.1286 13.999C8.28677 14.0022 8.43985 13.9348 8.55559 13.8112L11.0035 11.0067L13.4497 13.8121C13.5654 13.9358 13.7185 14.0031 13.8767 13.9999C14.0349 13.9967 14.1858 13.9232 14.2976 13.795C14.4095 13.6667 14.4736 13.4937 14.4764 13.3124C14.4791 13.1311 14.4204 12.9556 14.3126 12.8229L11.868 10.0175L14.3134 7.21298Z"
                fill="white"
              />
            </svg>
          </div>
        </div>
      </div>
    );
  }
}

export default NotificationBar;
