import React, { Component, createRef } from "react";
import Link from "../../../Link.js";
class Theme8 extends Component {
  constructor() {
    super();
    this.state = {
      scrolling: false,
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  };

  handleScroll = (event) => {;
    if (window.scrollY <= 30 && this.state.scrolling === true) {
      this.setState({ scrolling: false });
    } else if (window.scrollY > 30 && this.state.scrolling !== true) {
      this.setState({ scrolling: true });
    }
  };

  render() {
   
    return (
      <>
        <div className={this.props?.styles?.mobile_header_logoContainer}>
          <div className={`${this.props?.styles?.mobile_header_logoWrap} ${this.props.showAlertBar && this.state.scrolling ? this.props?.styles?.header_sticky_mobile : ''}`}>
            <Link title="Logo" key={"logo"} href={"/"} className="">
              <img
                className={this.props?.styles?.header_logo}
                src={`${
                  "/shared/api/media/" +
                  (this.props?.logo)
                }`}
                alt="logodata"
              />
            </Link>
          </div>
        </div>
      </>
    );
  }
}

export default Theme8;
