import React, { Component, createRef } from "react";
import Router from "next/router";
import { logout } from "../../../lib/logout";
import commonFunction from "../../../lib/utils/common";
import assistedBooking from "../../../lib/assistedBooking";
import assistedNewToken from "../../../lib/assistedNewToken";
import AssistedBooking from "@/commonComponents/assistedBooking";
import Link from "next/link";
import configUrl from "../../../../config/config.json";

class Menu extends Component {
  constructor(props) {
    super(props);
    this.menusWrapper = createRef();
    this.state = {
      hamburgerOpen: this.props.isopen,
      assistedBookingPopUp: false,
      assitedBookingData: {},
      btnClicked: false,
    };
  }

  componentDidMount() {
    console.log("emailmine", this.props);
    document.addEventListener("mousedown", this.handleClickOutside, false);
    const currentPath = window.location.pathname + window.location.search;
    if (currentPath.includes("myprofile?Linkindex=undefined")) {
      // Redirect to the home page
      window.location.href = "/";
    }
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside, false);
  }

  handleClickOutside = (event) => {
    if (
      this.menusWrapper.current &&
      !this.menusWrapper.current.contains(event.target)
    ) {
      this.props.closeMenusHandler(false);
    }
  };

  toggleHamburger = () => {
    this.setState({ hamburgerOpen: !this.state.hamburgerOpen });
  };

  onClickRedirect = (data, index) => {
    // Router.push({
    //   pathname: '/myprofile',
    //   query: `menu=${data.href}`,
    if (data.href == "Logout") {
      this.props.onclickLogout();
    } else {
      this.props.clickedTab(index);
      window.location.href = `/myprofile?Linkindex=${index}`;
    }
    // })
  };

  onClickProfile = () => {
    // this.props.clickedTab(0)
    // commonFunction.redirectTo('/myprofile')
    window.location.href = `/myprofile?Linkindex=0`;
  };
  onClickLogout = () => {
    logout()
      .then((responseJSON) => {
        if (
          responseJSON.status === true ||
          responseJSON.message === "Logout successfull"
        ) {
          this.setState({
            userFirststname: "",
            userType: "",
          });
          location.reload();
          commonFunction.redirectTo("/");
        } else {
        }
      })
      .catch((err) => {
        console.log("errrrrrrrrrrr", err);
      });
  };

  onClickHome = () => {
    this.props.clickedTab(0);
    commonFunction.redirectTo("/");
  };
  myBookingClickHandler = () => {
    this.props.clickedTab(1);
    window.location.href = `/myprofile?Linkindex=1`;
  };

  myQuotesClickHandler = () => {
    window.location.href = `/myprofile?Linkindex=4`;
  };

  reportsClickHandler = () => {
    window.location.href = `/myprofile?Linkindex=5`;
  };

  myClientsClickHandler = () => {
    window.location.href = `/myprofile?Linkindex=9`;
  };

  myEnquiriesClickHandler = () => {
    window.location.href = `/myprofile?Linkindex=1`;
  };

  importPNRClickHandler = () => {
    window.location.href = `/myprofile?Linkindex=6`;
  };

  myWalletClickHandler = () => {
    window.location.href = `/myprofile?Linkindex=7`;
  };

  settingClickHandler = () => {
    window.location.href = `/myprofile?Linkindex=8`;
  };

  changePassClickHandler = () => {
    window.location.href = `/myprofile?Linkindex=11`;
  };

  closeAssistedPopup = () => {
    this.props.closeMenusHandler(false);
  };

  handleAssistedBooking = async () => {
    this.setState({ btnClicked: true });
    if (this.props?.data?.user?.assit_bookerId === null) {
      const data = await assistedBooking(this.props.data.user.organisation.id);
      this.setState({ assitedBookingData: data });
      if (data) {
        this.setState(
          { assistedBookingPopUp: true, hamburgerOpen: false },
          () => {
            // this.setState({ btnClicked: false })
          }
        );
      } // Call the function passed as a prop with the data
    } else {
      const reqBody = {
        user_id: this.props?.data?.user?.assit_bookerId,
        assit_login: false,
        site_id: this.props?.data?.siteData?.data?.site_id || "",
      };
      const data = await assistedNewToken(reqBody);
      const { accessToken } = data;
      if (accessToken) {
        // this.props.router.reload();
        window.location.href = "/";
      }
    }
  };


   getMenu = (element, index) => {
    console.log("🚀 ~ getMenu ~ element:", element , index)

        if ((element.linkItem === 'My Wallet' || element.linkItem === 'Settings' || element.linkItem ==='Review Trip' ||element.linkItem ==='My Trip' ) && this.props?.data?.user?.user_type === 'STAFF') {
            return null;
        }
        if (element.linkItem === 'My Wallet' &&  (this.props?.data?.user?.user_type === 'CT'&&!this.props?.loginData?.corporate_detail?.is_admin || this.props?.data?.user?.user_type === 'TD') && this.props?.data?.user?.user_type?.type==='B2E') {
            return null;
        }
        if ((element.linkItem === 'My Trip'||element.linkItem === 'Review Trip') &&  (this.props?.data?.user?.user_type === 'TD' || this.props?.data?.user?.user_type === 'ADMIN') && this.props?.data?.user?.user_type?.type==='B2E') {
            return null;
        }
        if (element.linkItem === 'Review Trip' && this.props?.data?.user?.user_type?.type==='B2E'&&!this.props?.loginData?.corporate_detail?.is_approver) {
            return null;
        }
        if (element.linkItem === 'Import PNR' &&  !this.props?.data?.user?.import_pnr) {
            return null;
        }
        if ((element.linkItem === 'Trip Proposals'||element.linkItem === 'Reports') && (this.props?.data?.user?.user_type?.type==='B2E' && (this.props?.data?.user?.user_type !== 'TD'  && this.props?.data?.user?.user_type !== 'ADMIN'))) {
            return null;
        }
        if (element.linkItem === 'My Offline Bookings' &&  this.props?.data?.user?.user_type !== 'TD'  ) {
            return null;
        }
        
        if (element.linkItem === 'Offline Booking' &&  this.props?.data?.user?.user_type !== 'TD'  ) {
            return null;
        }
        if((element.linkItem === 'My Expense'||element.linkItem === 'Review Expense')&&  this.props?.data?.user?.user_type !== 'CT'){
            return null;
        }

        if (element.linkItem === 'Book Ancillary') {
            return null;
        }



        return <Link href={`${element?.link}`}>
        <span
          onClick={() => {
            this.props.clickedTab(element?.link);
          }}
        >
          <div className={this.props.styles.menu_icon}>
            {" "}
            <img
              type="image"
              src={element?.imgInActiveSrc}
              alt=""
            />
          </div>{" "}
          {element?.linkItem}
        </span>
      </Link>
    }

  render() {
    return (
      <div>
        {this.state.assistedBookingPopUp && (
          <AssistedBooking
            styles={this.props.data.assistedcss}
            data={this.state.assitedBookingData}
            setAssistedBokingPopup={() => {
              this.setState({ assistedBookingPopUp: false, btnClicked: false });
            }}
            theme={"theme4"}
            isMobile={true}
          />
        )}
        {this.props.isopen && (
          <>
            <div className={this.props.styles.nav_menu_backdrop}></div>
            <div
              ref={this.menusWrapper}
              className={this.props.styles.navigation_ineline}
            >
                <div className={this.props.styles.afterlogin}>
                  <div
                    className={`${this.props.styles.profile_User} ${this.props.styles.support_scnd}`}
                  >
                    <div class={this.props.styles.User_container}>
                      <div class={this.props.styles.flex_rowalign}>
                        <div
                          onClick={this.onClickHome}
                          class={this.props.styles.user_icon}
                        >
                          <img src="/theme4/User_profile.png" alt="User Icon" />
                        </div>
                        <div class={this.props.styles.welcome_message_right}>
                          <div class={this.props.styles.welcome_message}>
                            {this.props?.data?.user?.company_name &&
                              `Welcome, ` + this.props?.data?.user?.company_name}
                            {this.props?.data?.user?.first_name &&
                              `Welcome ` + this.props?.data?.user?.first_name}
                          </div>
                          <div class={this.props.styles.welcome_email}>

                            {this.props?.data?.user?.email &&
                              this.props?.data?.user?.email}
                          </div>
                          <div class={this.props.styles.welcome_currency}>
                            INR | English
                          </div>
                        </div>


                      </div>




                    </div>

                    <div class={this.props.styles.branch_container}>
                      <div
                        class={`${this.props.styles.branch_name} ${this.props.styles.branch_flx}`}
                      >
                        <span>
                          {" "}
                          {
                            this.props?.data?.user?.organisation?.type
                          } Name:{" "}
                        </span>
                        <span className={this.props.styles.banch_wd}>
                          {this.props?.branchName}
                        </span>
                      </div>
                      {this.props?.data?.user?.user_type != "CT" &&
                        this.props?.data?.user?.user_type != "TD" &&
                        this.props?.data?.user?.user_type != "AGENT" &&
                        this.props.data.user.is_assisted_booking_allowed && (
                          <div class={this.props.styles.assisted_booking}>
                            <button onClick={this.handleAssistedBooking}>
                              {this.props?.data?.user?.assit_bookerId ===
                                null && "Assisted Booking"}
                            </button>
                          </div>
                        )}
                      {this.props?.data?.user?.assit_bookerId && (
                        <div className={this.props.styles.assisted_booking}>
                          <button onClick={this.handleAssistedBooking}>
                            Back To Own Branch
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className={this.props.styles.profile_frst}>

                    {this.props.MenuList.length > 0 &&
                      this.props.MenuList.map((element, index) => {
                        return element.type?.map((typeres,ind)=>{
                          
                          return (
                              <>
                              {
                                  this.props?.data?.user?.type == typeres?this.getMenu(element, index):null
                              }
                              </>
                          )
      
                      })
                      })}

                  </div>
                </div>
              
            </div>
          </>
        )}
      </div>
    );
  }
}

export default Menu;
