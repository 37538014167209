import React, { Component } from "react";
import GoogleTranslate from "../../googleTranslate/custom.js";

class Theme8 extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    
    return (
      <>
        <ul
          className={
            this.props?.data?.showTranslation
              ? this.props.data.cssfile.navbar_right
              : `${this.props.data.cssfile.navbar_right} ${this.props.data.cssfile.handle_pointer}`
          }
        >
          <li
            className={this.props.data.cssfile.dropdown}
            onClick={() =>
              this.props.openLanguageModal()
            }
          >
            <span className={this.props.data.cssfile.dropdown_span}>
              <img
                src="/images/india-flag.png"
                alt="flag"
                width="20px"
                height="20px"
              />

              <div className={this.props.data.cssfile.znth_main}>
                <span>IN</span>
                <span className={this.props.data.cssfile.divider_line}>|</span>
                <span>{this.props.state.language.toUpperCase()}</span>
                <span className={this.props.data.cssfile.divider_line}>|</span>
                <span>INR</span>
                <span
                  className={
                    this.props?.data?.showTranslation
                      ? this.props.data.cssfile.translate_dropdown
                      : this.props.data.cssfile.profile_details_hide
                  }
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="7" height="7">
                    <path
                      fill-rule="evenodd"
                      d="M3.502 6.413.142.6h6.719L3.502 6.413z"
                    />
                  </svg>
                </span>
              </div>
              {this.props.state.openCurrencyPopup && (
                <div className={this.props.data.cssfile.language_dropMain}>
                  <div className={this.props.data.cssfile.language_mnCont}>
                    <div className={this.props.data.cssfile.language_wrap}>
                      <div className={this.props.data.cssfile.lang_title}>
                        {" "}
                        Select Language
                      </div>
                      <input
                        type="radio"
                        id="english"
                        name="language"
                        value="english"
                        checked
                      />
                      <label
                        className={this.props.data.cssfile.lang_label}
                        for="english"
                      >
                        English
                      </label>
                    </div>
                    <div className={this.props.data.cssfile.language_wrap}>
                      <div className={this.props.data.cssfile.lang_title}>
                        {" "}
                        Select Currency
                      </div>
                      <input
                        type="radio"
                        id="currency"
                        name="currency"
                        value="currency"
                        checked
                      />
                      <label
                        className={this.props.data.cssfile.lang_label}
                        for="english"
                      >
                        INR
                      </label>
                    </div>
                  </div>
                  <div className={this.props.data.cssfile.apply_btn}>
                    <button>Apply</button>
                  </div>
                </div>
              )}
            </span>
          </li>

          {/* Language currency dropdown */}
          {this.props.state.isTranslationModal &&
          this.props?.data?.siteData?.site
          ?.allow_multilingual &&
           (
              <GoogleTranslate
                style={this.props.data.googleTransalationCss}
                isMobile={false}
                closeTranslationModal={this.props.closeTranslationModal.bind(this.props.data)}
                isHomePage={
                  this.props?.data?.siteData?.site?.allow_multilingual
                }
                site_multi_languages={
                  this.props?.data?.siteData?.site?.site_multi_languages
                }
              />
            )}
          {/* Language currency dropdown */}
        </ul>
      </>
    );
  }
}

export default Theme8;
