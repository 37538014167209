import React, { Component, createRef } from "react";
import Link from "../../../Link.js";
import MoreMenu from "../../Moremenu/index.js";
import commonFunction from "../../../lib/utils/common.js";

class Theme8 extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    return (
      <>
        <div
          className={this.props.data.cssfile.afterlogin}
          ref={this.setCalendarWrapperRef}
          onClick={() => {
            this.props.onClickArrow();
          }}
        >
          {this.props.state.userFirststname != undefined &&
          this.props.state.userFirststname != null &&
          this.props.state.userFirststname != "" ? (
            <div className={this.props.data.cssfile.afterloginNew}>
              {" "}
              <p style={{ fontSize: 12 }}>
                {" "}
                Hi {`${this.props?.data?.user?.first_name} `}
              </p>
              <span
                className={this.props.data.cssfile.afterloginArrow}
                onClick={() => {
                  this.props.onClickArrow();
                }}
              >
                ▾
              </span>
              <div
                className={
                  this.props.state.isShowPopup
                    ? this.props.data.cssfile.show
                    : this.props.data.cssfile.profile_details_hide
                }
                onMouseLeave={() => {
                  this.props.onMouseLeave();
                }}
              >
                <div className={this.props.data.cssfile.profile_frst}>
                  <span
                    onClick={() => {
                      this.props.onClickProfile();
                    }}
                  >
                    {" "}
                    <div className={this.props.data.cssfile.menu_icon}>
                      <img
                        type="image"
                        src="/images/profile-01.svg"
                        alt="profile"
                      />
                    </div>
                    My Profile
                  </span>
                  <span onClick={this.myBookingClickHandler}>
                    {" "}
                    <div className={this.props.data.cssfile.menu_icon}>
                      <img
                        type="image"
                        src="/images/booking-01.svg"
                        alt="mybooking"
                      />
                    </div>
                    My Trips
                  </span>
                  <span
                    onClick={() => {
                      this.props.onClickLogout();
                    }}
                  >
                    {" "}
                    <div className={this.props.data.cssfile.menu_icon}>
                      <img
                        type="image"
                        src="/images/logout-01.svg"
                        alt="Logout"
                      />
                    </div>
                    Logout
                  </span>
                </div>
              </div>
            </div>
          ) : (
            <div
              className={this.props.data.cssfile.login_btn}
              onClick={() => {
                this.props.data.onClickHeaderLogin("LogIn");
              }}
            >
              <a href="#" title="My Account">
                <div className={this.props.data.cssfile.Login_btn_cont}>
                  <button className={this.props.data.cssfile.login_button}>
                    Sign In
                  </button>
                </div>
              </a>
            </div>
          )}

          {this.props.state.openInitialPopup && (
            <div
              className="logindrop"
              id="loginDrop"
              style={{
                display: "block",
                width: 140,
                borderRadius: 10,
                top: 60,
              }}
            >
              <div
                className="ax-head-menu"
                style={loginStyle}
                onClick={() => {
                  this.props.data.onClickHeaderLogin("LogIn");
                }}
              >
                <img
                  style={{ filter: "brightness(100)" }}
                  type="image"
                  src="/images/loginew.svg"
                  alt="Sign In"
                />
                <span style={{ color: "white" }}>Sign In</span>
              </div>
              <div
                className="ax-head-menu"
                style={signupStyle}
                onClick={() => {
                  this.props.data.onClickHeaderLogin("LogIn");
                }}
              >
                <img type="image" src="/images/registere.svg" alt="Sign Up" />
                <span>Sign Up</span>
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
}

export default Theme8;
