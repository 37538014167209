import React, { Component, createRef } from "react";
import Link from "../../../Link.js";
import MoreMenu from "../../Moremenu/index.js";
import commonFunction from "../../../lib/utils/common.js";

class Theme8 extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    return (
      <>
        <div>
          <h2 className={this.props.data.cssfile.header_allService}>
            <div>
              <div className={this.props.data.cssfile.header_all_section}>
                {!commonFunction.isValueEmpty(this.props) &&
                  !commonFunction.isValueEmpty(this.props.data) &&
                  !commonFunction.isValueEmpty(this.props.data.headermenu) &&
                  !commonFunction.isValueEmpty(
                    this.props.data.headermenu.menu
                  ) &&
                  this.props.data.headermenu.menu
                    .filter((ele) => ele.status.toLowerCase() == "active")
                    .slice(0, 7)
                    .map(
                      (link, i) =>
                        link?.status.toLowerCase() === "active" && (
                          <div
                            key={"headerMenu" + i}
                            className={`${
                              this.props.data.cssfile.header_sections
                            } ${
                              this.props.state.activeLink === link.text
                                ? this.props.data.cssfile.active_hometab
                                : ""
                            }`}
                          >
                            {link.text != "Offline" && link.code != "others" ? (
                              <Link
                                title={link.text}
                                key={link.text}
                                href={link.url}
                                className=""
                              >
                                {link.text}
                                <img
                                  src={`${
                                    "/shared/api/media/" +
                                    this.props?.data?.siteData?.site.id +
                                    "/" +
                                    link.icon
                                  }`}
                                  alt={link.text}
                                />
                              </Link>
                            ) : (
                              <a
                                title={link.text}
                                key={link.text}
                                href={link.url}
                                target="_blank"
                                className=""
                              >
                                {link.text}
                                <img
                                  src={`${
                                    "/shared/api/media/" +
                                    this.props?.data?.siteData?.site.id +
                                    "/" +
                                    link.icon
                                  }`}
                                  alt={link.text}
                                />
                              </a>
                            )}
                          </div>
                        )
                    )}
                {this.props.data?.headermenu?.menu?.length > 0 && this.props.data.headermenu.menu.filter(
                  (ele) => ele.status.toLowerCase() == "active"
                ).length > 7 && (
                  <MoreMenu
                    menuItems={this.props.data.headermenu.menu.slice(7)}
                    styles={this.props.data.cssfile}
                    setMoreWrapperRef={this.setMoreWrapperRef}
                    state={this.props.state.openMorePopup}
                    setMorePopupHandler={this.setMorePopupHandler}
                    site_id={this.props?.data?.siteData?.site.id}
                    showIcon={false}
                  />
                )}
              </div>
            </div>
          </h2>
        </div>
      </>
    );
  }
}

export default Theme8;
