import React, { Component } from "react";

class MobFooterNavbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      home: true,
      isSearch: false,
      isManageBooking: false,
    };
  }

  onClickHome = () => {
    window.location.href = "/";
    this.setState({ 
      home: true ,
      isSearch: false,
      isManageBooking: false
    });
  };

  onClickSearch = () => {
    if(window.location.pathname != "/") {
      this.onClickHome();
    } else {
      window.scrollTo(0, 0);
      this.setState({ 
        home: false ,
        home: true,
        isManageBooking: false
      });
    }
  };

  handleManageBooking = () => {
    this.setState({ 
      home: false ,
      isSearch: false,
      isManageBooking: true
    });
  };

  render() {
    const styles = this.props.data.styles;
    const isOtherPage = this.props.data.isOtherPage ? this.props.data.isOtherPage : false;
    
    return (
      <>
        <div className={styles.mobile_footer}>
          <div className={styles.mobile_footer__container}>
            <div className={styles.mobile_footer__home}>
              <div  onClick={() => this.onClickHome()}
              className={`${styles.mobile_footer__home_content} ${ !isOtherPage && this.state.home ? styles.mobile_footer__home_active : ""}`}>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M20.0383 6.81969L14.2783 2.78969C12.7083 1.68969 10.2983 1.74969 8.78828 2.91969L3.77828 6.82969C2.77828 7.60969 1.98828 9.20969 1.98828 10.4697V17.3697C1.98828 19.9197 4.05828 21.9997 6.60828 21.9997H17.3883C19.9383 21.9997 22.0083 19.9297 22.0083 17.3797V10.5997C22.0083 9.24969 21.1383 7.58969 20.0383 6.81969ZM12.7483 17.9997C12.7483 18.4097 12.4083 18.7497 11.9983 18.7497C11.5883 18.7497 11.2483 18.4097 11.2483 17.9997V14.9997C11.2483 14.5897 11.5883 14.2497 11.9983 14.2497C12.4083 14.2497 12.7483 14.5897 12.7483 14.9997V17.9997Z"
                    fill="#7F7F7C"
                  />
                </svg>

                <div className={styles.mobile_footer__home_text}>Home</div>
              </div>
            </div>

            <div  className={`${this.state.isManageBooking ? "isManageBookingclick" : "isManageBookingclick"} ${styles.managebookingnav} `} onClick={() => this.props.data?.handleManageBooking(true)} >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
             
            >
              <path
                d="M8 2V5"
                stroke="#7F7F7C"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M16 2V5"
                stroke="#7F7F7C"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M3.5 9.08984H20.5"
                stroke="#7F7F7C"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M21 8.5V17C21 20 19.5 22 16 22H8C4.5 22 3 20 3 17V8.5C3 5.5 4.5 3.5 8 3.5H16C19.5 3.5 21 5.5 21 8.5Z"
                stroke="#7F7F7C"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M15.6937 13.7002H15.7027"
                stroke="#7F7F7C"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M15.6937 16.7002H15.7027"
                stroke="#7F7F7C"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M11.9945 13.7002H12.0035"
                stroke="#7F7F7C"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M11.9945 16.7002H12.0035"
                stroke="#7F7F7C"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M8.29529 13.7002H8.30427"
                stroke="#7F7F7C"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M8.29529 16.7002H8.30427"
                stroke="#7F7F7C"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            </div>

            <div className={` ${this.state.isSearch ? "" : ""}`} onClick={() => this.onClickSearch()} >
            <svg
          
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12.5912 23.2584L12.5802 23.2604L12.5092 23.2954L12.4892 23.2994L12.4752 23.2954L12.4042 23.2604C12.3936 23.2571 12.3856 23.2588 12.3802 23.2654L12.3762 23.2754L12.3592 23.7034L12.3642 23.7234L12.3742 23.7364L12.4782 23.8104L12.4932 23.8144L12.5052 23.8104L12.6092 23.7364L12.6212 23.7204L12.6252 23.7034L12.6082 23.2764C12.6056 23.2658 12.5999 23.2598 12.5912 23.2584ZM12.8562 23.1454L12.8432 23.1474L12.6582 23.2404L12.6482 23.2504L12.6452 23.2614L12.6632 23.6914L12.6682 23.7034L12.6762 23.7104L12.8772 23.8034C12.8899 23.8068 12.8996 23.8041 12.9062 23.7954L12.9102 23.7814L12.8762 23.1674C12.8729 23.1554 12.8662 23.1481 12.8562 23.1454ZM12.1412 23.1474C12.1368 23.1448 12.1315 23.1439 12.1265 23.145C12.1215 23.1461 12.1171 23.1491 12.1142 23.1534L12.1082 23.1674L12.0742 23.7814C12.0749 23.7934 12.0806 23.8014 12.0912 23.8054L12.1062 23.8034L12.3072 23.7104L12.3172 23.7024L12.3212 23.6914L12.3382 23.2614L12.3352 23.2494L12.3252 23.2394L12.1412 23.1474Z"
                fill="#7F7F7C"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M10.4993 2C9.14387 2.00012 7.80814 2.32436 6.60353 2.94569C5.39893 3.56702 4.36037 4.46742 3.57451 5.57175C2.78866 6.67609 2.27829 7.95235 2.08599 9.29404C1.89368 10.6357 2.02503 12.004 2.46906 13.2846C2.91308 14.5652 3.65692 15.7211 4.63851 16.6557C5.6201 17.5904 6.81098 18.2768 8.11179 18.6576C9.4126 19.0384 10.7856 19.1026 12.1163 18.8449C13.447 18.5872 14.6967 18.015 15.7613 17.176L19.4133 20.828C19.6019 21.0102 19.8545 21.111 20.1167 21.1087C20.3789 21.1064 20.6297 21.0012 20.8151 20.8158C21.0005 20.6304 21.1057 20.3796 21.108 20.1174C21.1102 19.8552 21.0094 19.6026 20.8273 19.414L17.1753 15.762C18.1633 14.5086 18.7784 13.0024 18.9504 11.4157C19.1223 9.82905 18.8441 8.22602 18.1475 6.79009C17.4509 5.35417 16.3642 4.14336 15.0116 3.29623C13.659 2.44911 12.0952 1.99989 10.4993 2ZM3.99928 10.5C3.99928 8.77609 4.6841 7.12279 5.90308 5.90381C7.12207 4.68482 8.77537 4 10.4993 4C12.2232 4 13.8765 4.68482 15.0955 5.90381C16.3145 7.12279 16.9993 8.77609 16.9993 10.5C16.9993 12.2239 16.3145 13.8772 15.0955 15.0962C13.8765 16.3152 12.2232 17 10.4993 17C8.77537 17 7.12207 16.3152 5.90308 15.0962C4.6841 13.8772 3.99928 12.2239 3.99928 10.5Z"
                fill="#7F7F7C"
              />
            </svg>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default MobFooterNavbar;
