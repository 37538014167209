import { getSystemDetails } from "./getSystemDetails";

export async function login(req) {
  const { ip, userAgent } = await getSystemDetails();
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-Client-IP": ip,
      "X-Client-User-Agent": userAgent,
      device_type: localStorage.getItem("deviceType") || "",
      device_id: localStorage.getItem("deviceId") || "",
      client_data: localStorage.getItem("clientData") || "",
    },
    body: JSON.stringify(req),
  };

  return fetch("/shared/api/login", requestOptions).then((response) =>
    response.json()
  );
}

export async function userLogin(req) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      device_type: localStorage.getItem("deviceType") || "",
      device_id: localStorage.getItem("deviceId") || "",
      client_data: localStorage.getItem("clientData") || "",
    },
    body: JSON.stringify(req),
  };

  return fetch("/shared/api/userLogin", requestOptions).then((response) =>
    response.json()
  );
}

export async function userVerify(req) {
  const { ip, userAgent } = await getSystemDetails();
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-Client-IP": ip,
      "X-Client-User-Agent": userAgent,
      device_type: localStorage.getItem("deviceType") || "",
      device_id: localStorage.getItem("deviceId") || "",
      client_data: localStorage.getItem("clientData") || "",
    },
    body: JSON.stringify(req),
  };

  return fetch("/shared/api/userVerify", requestOptions).then((response) =>
    response.json()
  );
}

export function resendOtp(req) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      device_type: localStorage.getItem("deviceType") || "",
      device_id: localStorage.getItem("deviceId") || "",
      client_data: localStorage.getItem("clientData") || "",
    },
    body: JSON.stringify(req),
  };

  return fetch("/shared/api/resendOtp", requestOptions).then((response) =>
    response.json()
  );
}

export async function setCookiUserToken(req) {
  const { ip, userAgent } = await getSystemDetails();
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-Client-IP": ip,
      "X-Client-User-Agent": userAgent,
      device_type: localStorage.getItem("deviceType") || "",
      device_id: localStorage.getItem("deviceId") || "",
      client_data: localStorage.getItem("clientData") || "",
    },
    body: JSON.stringify(req),
  };
  return fetch("/shared/api/setcookiusertoken", requestOptions).then(
    (response) => response.json()
  );
}
