import FingerprintJS from "@fingerprintjs/fingerprintjs";

export const fetchClientInfo = async () => {
  let clientData = {
    screenResolution: "",
    deviceId: "",
    ip: "",
    location: "",
  };
  try {
    // Fetch IP and location data
    const ip = await getIP();
    const location = await getLocation();
    const deviceId = await getDeviceId();

    // Get client-side details
    clientData = {
      screenResolution: `${window.screen.width}x${window.screen.height}` || "",
      deviceId: deviceId || "",
      ip: ip || "",
      location: location || "",
    };

    return clientData;
  } catch (error) {
    console.error("Error fetching client info data:", error);
    return clientData;
  }
};

const getDeviceId = async () => {
  let deviceId = "";
  try {
    // Generate Device ID using FingerprintJS
    const fp = await FingerprintJS.load();
    const result = await fp.get();
    if (result) {
      deviceId = result?.visitorId;
    }

    return deviceId;
  } catch (error) {
    console.error("Error fetching device id data:", error);
    return deviceId;
  }
};

const getIP = async () => {
  let ip = "";
  try {
    const response = await fetch("https://api.ipify.org/?format=json");
    if (response.ok) {
      const result = await response.json();
      if (result) {
        ip = result?.ip;
      }
    }

    return ip;
  } catch (error) {
    console.error("Error fetching ip data:", error);
    return ip;
  }
};

const getLocation = async () => {
  let location = "";
  try {
    const response = await fetch("https://get.geojs.io/v1/ip/geo.json");
    if (response.ok) {
      const result = await response.json();
      if (result) {
        location = result;
      }
    }

    return location;
  } catch (error) {
    console.error("Error fetching location data:", error);
    return location;
  }
};

const generateUUID = async () => {
  try {
    // Generate a UUID (version 4)
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
      const r = (Math.random() * 16) | 0;
      const v = c === "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  } catch (error) {
    console.error("Error:", error);
  }
};
