import React, { Component } from "react";
import HeaderLogo from "../../HeaderLogo/index.js";
import UploadLogo from "store/uploadLogo";
import NotificationBar from "@/commonComponents/notificationBar/index.js";

class Header8 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isNotificaitionBar: true,
    };
  }

  onClickNotification = () => {
    this.setState({
      isNotificaitionBar: false,
    });
  };

  render() {
    return (
      <div>
        {window.location.pathname == "/" && this.props?.data?.showNotificaitionBar && this.state.isNotificaitionBar &&
          <NotificationBar
            styles={this.props.data.NotificationBarStyles}
            theme={"theme8"}
            onClickNotification={this.onClickNotification}
            newsAlert={this.props.data?.newsAlert}
          />
        }
        <HeaderLogo
          styles={this.props.data?.cssfile}
          UploadLogo={UploadLogo}
          logo={`${this.props?.data?.site_id}/${this.props?.data?.headermenu?.mobile?.logo}`}
          showAlertBar={window.location.pathname == "/" && this.props?.data?.showNotificaitionBar && this.state.isNotificaitionBar}
        />
      </div>
    );
  }
}

export default Header8;
