import { getSystemDetails } from "./getSystemDetails";
export async function logout() {
  const { ip, userAgent } = await getSystemDetails();
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-Client-IP": ip,
      "X-Client-User-Agent": userAgent,
      device_type: localStorage.getItem("deviceType") || "",
      device_id: localStorage.getItem("deviceId") || "",
      client_data: localStorage.getItem("clientData") || "",
    },
    body: JSON.stringify({}),
  };
  return fetch("/shared/api/auth/logout", requestOptions).then((response) =>
    response.json()
  );
}
