import React, { Component, createRef } from "react";
import Link from "../../../Link.js";
import commonFunction from "../../../lib/utils/common.js";

class Theme8 extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    console.log("this.props==>", this.props);

    return (
      <>
        <div
          className={this.props.data.cssfile.suport_cont}
          ref={this.setSupportWrapperRef}
        >
          <div
            className={this.props.data.cssfile.znth_support}
            onClick={() => {
              this.setState({
                openSupportPopup: !this.state.openSupportPopup,
              });
            }}
          >
            <span>Support</span>
            <span className={this.props.data.cssfile.dropZ_arrow}>
              <svg xmlns="http://www.w3.org/2000/svg" width="7" height="7">
                <path
                  fill-rule="evenodd"
                  d="M3.502 6.413.142.6h6.719L3.502 6.413z"
                />
              </svg>
            </span>
          </div>

          {this.state.openSupportPopup && (
            <div
              className={this.props.data.cssfile.supportdropNew}
              style={{ display: "block" }}
            >
              <a
                href={`tel:${this.props?.data?.siteData?.site?.support_number}`}
              >
                <div className={this.props.data.cssfile.header_menuZth}>
                  <img src="/images/et-hd-phn.png" alt="" />
                  {this.props?.data?.siteData?.site?.support_number}
                </div>
              </a>
              <a
                href={`mailto:${this.props?.data?.siteData?.site?.support_email}`}
              >
                <div className={this.props.data.cssfile.header_menuZth}>
                  <img
                    src="/images/et-hd-email.png"
                    alt=""
                    width="20px"
                    height="20px"
                  />
                  {this.props?.data?.siteData?.site?.support_email}
                </div>
              </a>
            </div>
          )}
        </div>
      </>
    );
  }
}

export default Theme8;
