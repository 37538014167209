import '@/css/tailwind.css'
import '@/css/prism.css'
import '@/css/global.css'
import '../../common/styles/universal.css'
// import '@/css/universal.css' //added by sudhir for overide eixsting css globally
import { ThemeProvider } from 'next-themes'
import Head from 'next/head'
import { useEffect, useState } from 'react'
import siteMetadata from '@/data/siteMetadata'
import LayoutWrapper from '@/components/LayoutWrapper'
import { ClientReload } from '@/components/ClientReload'
import commonFunction from '../lib/utils/common'
import { checksession } from '../../common/lib/checksession'
import Router, { useRouter } from 'next/router'
//import AppLoader from '../components/appLoader'
import Analytics from '../../common/analytics'
import PwaInstall from '../../common/components/pwaInstall'
import PwaInstallcss from '../../common/components/pwaInstall/pwaInstall.module.css'
import Skeleton from '@/components/skeleton'
import { Theme } from '@vernost_dev/vms-react-components/dist/Theme';

const isDevelopment = process.env.NODE_ENV === 'development'
const isSocket = process.env.SOCKET

export default function App({ Component, pageProps }) {
  const [checkSessionData, setCheckSessionData] = useState('')
  const [getFontCss, setFontCss] = useState('')
  const [pwaInstallShow, setpwaInstallShow] = useState(true)

  // Hide splash screen shen we are server side
  // useEffect(() => {
  //   if (typeof window !== 'undefined') {
  //     const loader = document.getElementById('globalLoader')
  //     if (loader) {
  //       // setTimeout(() => {
  //       //   loader.style.display = 'none'
  //       // }, 100)
  //     }
  //   }
  // }, [])

  const [isLoading, setIsLoading] = useState(true)
  const [currentUrl, setCurrentUrl] = useState('')
  const router = useRouter()
  useEffect(() => {
    if (pageProps) {
      setIsLoading(false)
    }
  }, [pageProps])
  // // console.log(
  // //   '=====App router.query=======: ',
  // //   router.query,
  // //   '==',
  // //   Object.keys(router.query).length
  // // )

  // // Router change loader
  // useEffect(() => {
  //   console.log("router.query====>", JSON.stringify(router.query));
  //   Router.events.on('routeChangeStart', (url) => {
  //     setIsLoading(true)
  //   })
  //   Router.events.on('routeChangeComplete', (url) => {
  //     setIsLoading(false)
  //   })
  //   Router.events.on('routeChangeError', (url) => {
  //     setIsLoading(false)
  //   })
  // }, [router.query])

  useEffect(() => {
    if(pageProps?.home?.data?.site?.site_theme == 'theme5') {
      const handleRouteChangeStart = (url) => {
        if (
          url !== currentUrl &&
          ['/flights', '/hotels', '/activities', '/holidays', '/', '/cars'].includes(url)
        ) {
          setIsLoading(true)
        }
      }

      const handleRouteChangeComplete = () => {
        setIsLoading(false)
        setCurrentUrl(window.location.href)
      }

      const handleRouteChangeError = () => {
        setIsLoading(false)
        setCurrentUrl(window.location.href)
      }

      Router.events.on('routeChangeStart', handleRouteChangeStart)
      Router.events.on('routeChangeComplete', handleRouteChangeComplete)
      Router.events.on('routeChangeError', handleRouteChangeError)

      return () => {
        Router.events.off('routeChangeStart', handleRouteChangeStart)
        Router.events.off('routeChangeComplete', handleRouteChangeComplete)
        Router.events.off('routeChangeError', handleRouteChangeError)
      }
    }
  }, [currentUrl])

  useEffect(() => {
    if (
      !commonFunction.isValueEmpty(pageProps) &&
      !commonFunction.isValueEmpty(pageProps.home) &&
      !commonFunction.isValueEmpty(pageProps.home.data) &&
      !commonFunction.isValueEmpty(pageProps.home.data.site)
    ) {
      var stylesheet = document.querySelector(':root')
      !commonFunction.isValueEmpty(pageProps.home.data.site.secondary_color) &&
        stylesheet.style.setProperty('--secondary_color', pageProps.home.data.site.secondary_color)
      !commonFunction.isValueEmpty(pageProps.home.data.site.primary_color) &&
        stylesheet.style.setProperty('--primary_color', pageProps.home.data.site.primary_color)
      !commonFunction.isValueEmpty(pageProps.home.data.site.buttons_color) &&
        stylesheet.style.setProperty('--buttons_color', pageProps.home.data.site.buttons_color)
      !commonFunction.isValueEmpty(pageProps.home.data.site?.highlight_color) &&
        stylesheet.style.setProperty('--highlight_color', pageProps.home.data.site?.highlight_color)
      !commonFunction.isValueEmpty(pageProps.home.data.site.font_color)
        ? stylesheet.style.setProperty('--font_color', pageProps.home.data.site.font_color)
        : stylesheet.style.setProperty('--font_color', '#5c5c5c')
      !commonFunction.isValueEmpty(pageProps.home.data.site.font_family)
        ? stylesheet.style.setProperty('--font_family', pageProps.home.data.site.font_family)
        : stylesheet.style.setProperty('--font_family', "'Open Sans', sans-serif")
      let fontCssLink = !commonFunction.isValueEmpty(pageProps.home.data.site.font_css_link)
        ? pageProps.home.data.site.font_css_link
        : 'https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600&display=swap'
      setFontCss(fontCssLink)
    }
    // console.log(
    //   '==pageProps?.home?.data?.data?.access_token===',
    //   pageProps?.home?.data?.data?.access_token
    // )
    if (pageProps?.home?.data?.data?.access_token) {
      setCheckSessionData(pageProps?.home?.data?.data?.user)
    }
    // checksession()
    //   .then((responseJSON) => {
    //     console.log('==checksession app==', responseJSON)
    //     if (responseJSON.status === true) {
    //       if (
    //         !commonFunction.isValueEmpty(responseJSON) &&
    //         Object.keys(responseJSON).length > 0
    //       ) {
    //         setCheckSessionData(responseJSON)
    //       }
    //     }
    //   })
    //   .catch((err) => {
    //     console.log(err)
    //   })

    //pwaInstall pop hide in case of mobile app

    if (localStorage.getItem('appType') == 'mobileapp') {
      setpwaInstallShow(false)
    }
  }, [])

  return (
    <ThemeProvider attribute="class" defaultTheme={siteMetadata.theme}>
      <Theme>
        <Head>
          <link href={getFontCss} rel="stylesheet" as="font" />
        </Head>
        {/* ClientReload */}
        {isDevelopment && isSocket && <ClientReload />}
        {/* <Analytics /> */}
        {pageProps?.home?.data?.site?.analytics_module && (
          <Analytics {...pageProps?.home?.data?.site?.analytics_module} />
        )}
        {/* AppLoader */}
        {/* {isLoading && <AppLoader />} */}
        {/* pwa install popup */}
        {Object.keys(router.query).length == 0 &&
          pageProps?.home?.data?.site?.allow_pwa_module == true &&
          pageProps?.home?.data?.site?.pwa_config?.popup == true &&
          pwaInstallShow == true && (
            <PwaInstall
              style={PwaInstallcss}
              isMobile={pageProps?.isMobile}
              theme={pageProps?.home?.data?.site?.site_theme}
            />
          )}
        {/* LayoutWrapper */}
        {isLoading ? (
          <Skeleton />
        ) : (
          <LayoutWrapper
            {...pageProps}
            sessionData={!commonFunction.isValueEmpty(checkSessionData) ? checkSessionData : ''}
          >
            <Component
              {...pageProps}
              sessionData={!commonFunction.isValueEmpty(checkSessionData) ? checkSessionData : ''}
            />
          </LayoutWrapper>
        )}
      </Theme>
    </ThemeProvider>
  )
}
