import Theme8 from "./theme8"

export function ServicesSection(props) {
    const { theme } = props
    switch (theme) {
        case 'theme8':
            return <Theme8 {...props} />
        
        default:
            return <Theme8 {...props} />
    }
}

export default ServicesSection;