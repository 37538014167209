import React, { Component } from "react";
import commonFunction from "../../../lib/utils/common.js";
import { logout } from "../../../lib/logout.js";
import chat from "../../../lib/chat.js";
import Analytics from "../../../analytics/index.js";
import UploadLogo from "store/uploadLogo";
import HeaderLogo from "../../HeaderLogo/index.js";
import ServicesSection from "../../ServicesSection/index.js";
import SupportSection from "../../SupportSection/index.js";
import LanguageSection from "../../LanguageSection/index.js";
import LoginSection from "../../LoginSection/index.js";
import NotificationBar from "@/commonComponents/notificationBar/index.js";
class Header8 extends Component {
  constructor() {
    super();
    this.state = {
      isShowPopup: false,
      userFirststname: "",
      userType: "",
      openInitialPopup: false,
      onHoverSupport: false,
      transform: null,
      scrolling: false,
      openSupportPopup: false,
      openCurrencyPopup: false,
      activeLink: "",
      openMorePopup: false,
      pathname: "",
      isTranslationModal: false,
      language: "en",
      isNotificaitionBar: true,
    };
    this.style = {
      color: "red",
    };
  }



  componentDidMount() {
    let url = window.location.pathname;
    if (this.props?.data?.siteData?.site?.allow_multilingual) {
      let googtransCookie = commonFunction.getCookie("googtrans");
      let language =
        googtransCookie != "" ? googtransCookie.split("/")[2] : "en";
      this.setState({ language });
    }
    this.setState({ pathname: url });
    let defaultService = this.props.data?.headermenu?.menu?.length > 0 && this.props.data.headermenu.menu.filter((ele) => ele.status.toLowerCase() == 'active')
    if (defaultService.length > 0 ) {
      this.setState({ activeLink: defaultService[0].code});
    }
    
    if ( url.includes("holiday") || url.includes("holidays")) {
      this.setState({ activeLink: "Holidays" });
    }
    if (url.includes("flight") || url.includes("flights")) {
      this.setState({ activeLink: "Flights" });
    }
    if (url.includes("hotel") || url.includes("hotels")) {
      this.setState({ activeLink: "Hotels" });
    }
    if (url.includes("activity") || url.includes("activities")) {
      this.setState({ activeLink: "Activities" });
    }
    if (url.includes("car") || url.includes("cars")) {
      this.setState({ activeLink: "Cabs" });
    }
    //check if varification token is there
    window.addEventListener("scroll", this.handleScroll);
    document.addEventListener("mousedown", this.handleCalendarClickOutside);
    chat(this.props?.data?.siteData?.site?.chat, this.props?.data?.isMobile);

    if (this.props?.data?.user?.status) {
      this.setState({
        userFirststname: this.props?.data?.user?.first_name
          ? this.props?.data?.user?.first_name.charAt(0)
          : this.props?.data?.user?.email.charAt(0),
        userType: this.props?.data?.user?.type,
      });
    } else {
      this.setState({
        userFirststname: "",
        userType: "",
      });
    }
    
  }

  componentWillUnmount = () => {
    document.removeEventListener("mousedown", this.handleCalendarClickOutside);
    window.removeEventListener("scroll", this.handleScroll);
  };

  setCalendarWrapperRef = (node) => {
    this.calendarWrapperRef = node;
  };

  setSupportWrapperRef = (node) => {
    this.supportWrapperRef = node;
  };
  setMoreWrapperRef = (node) => {
    this.MoreWrapperRef = node;
  };

  setCurrencyWrapperRef = (node) => {
    this.currencyWrapperRef = node;
  };


  handleCalendarClickOutside = (event) => {
    if (
      !this.calendarWrapperRef ||
      !this.calendarWrapperRef.contains(event.target)
    ) {
      this.setState({ openInitialPopup: false });
    }

    if (
      !this.supportWrapperRef ||
      !this.supportWrapperRef.contains(event.target)
    ) {
      this.setState({ openSupportPopup: false });
    }
    if (!this.MoreWrapperRef || !this.MoreWrapperRef.contains(event.target)) {
      this.setState({ openMorePopup: false });
    }

    if (
      !this.currencyWrapperRef ||
      !this.currencyWrapperRef.contains(event.target)
    ) {
      this.setState({ openCurrencyPopup: false });
    }
  };

  checkSessionApi = () => {
    if (
      !commonFunction.isValueEmpty(this?.props?.data?.sessionData) &&
      !commonFunction.isValueEmpty(this?.props?.data?.sessionData) &&
      this?.props?.data?.sessionData?.status === true
    ) {
      this.setState(
        {
          userFirststname:
            !commonFunction.isValueEmpty(
              this?.props?.data?.sessionData?.email
            ) &&
            (!commonFunction.isValueEmpty(
              this?.props?.data?.sessionData?.first_name
            )
              ? this?.props?.data?.sessionData?.first_name.charAt(0)
              : this?.props?.data?.sessionData?.email.charAt(0)),
          userType:
            !commonFunction.isValueEmpty(
              this?.props?.data?.sessionData?.type
            ) && this?.props?.data?.sessionData?.type,
        },
        () => {}
      );
    } else {
      this.setState({
        userFirststname: "",
        userType: "",
      });
    }
  };

  onClickArrow = () => {
    this.setState({
      isShowPopup: !this.state.isShowPopup,
    });
  };

  onMouseLeave = () => {
    this.setState({
      isShowPopup: false,
    });
  };

  
  onClickProfile = () => {
    window.location.href = "/myprofile";
  };

  onClickLogout = () => {
    logout()
      .then((responseJSON) => {
        if (
          responseJSON.status === true ||
          responseJSON.message === "Logout successfull"
        ) {
          this.setState({
            userFirststname: "",
            userType: "",
          });
     
          if (this.props?.data?.siteData?.site?.business_b2b) {
            window.location.pathname != "/login"
              ? (window.location.href = "/login")
              : null;
          } else {
            window.location.reload();
          }
        } else {
        }
      })
      .catch((err) => {});
  };

  myBookingClickHandler = () => {
    window.location.href = `/myprofile?Linkindex=1`;
  };

  setMorePopupHandler = () => {
    this.setState({
      openMorePopup: !this.state.openMorePopup,
    });
  };

  closeTranslationModal = () => {
    this.setState({ isTranslationModal: false });
  };

  openLanguageModal = () => {
    this.setState({isTranslationModal: !this.state.isTranslationModal, });
  };

  handleScroll = (event) => {
    // console.log("clicked");
    if (window.scrollY <= 50 && this.state.scrolling === true) {
      this.setState({ scrolling: false });
      // console.log("false");
    } else if (window.scrollY > 50 && this.state.scrolling !== true) {
      this.setState({ scrolling: true });
      // console.log("true");
    }
  };

  onClickNotification = () => {
    this.setState({
      isNotificaitionBar: false,
    });
  };

  render() {
    
    return (
      <>
        {/* Header for HomePage starts */}
    
        <Analytics {...this.props?.data?.siteData?.site?.analytics_module} />
         {window.location.pathname == "/" && this.props?.data?.showNotificaitionBar && this.state.isNotificaitionBar &&
                          <NotificationBar
                            styles={this.props.data.NotificationBarStyles}
                            theme={"theme8"}
                            onClickNotification={this.onClickNotification}
                            newsAlert={this.props.data?.newsAlert}
                          />
                       }
        <header
             className={`${this.props.data.cssfile.new_header_wrap}  ${
              (this.state.scrolling) ? this.props.data.cssfile.header_sticky : ""
            }`}
          >
            <div className={this.props.data.cssfile.header_section}>
              <div className="flex flex-row justify-between items-center container-1191 mx-auto">
                <div className={this.props.data.cssfile.header_Container}>

                  {/* Logo Section starts ==> */}   
                  {this.props.data?.isShowLogoSection && 
                  <>
                  <HeaderLogo
                      styles={this.props.data.cssfile}
                      UploadLogo={UploadLogo}
                      logo={this.props?.data?.headermenu?.logo}
                    />
                  </>
                 }
                  {/* Logo Section end ==> */}       

                 {/* Services Section starts ==> */}   
                  {this.props.data?.isServiceSection &&      
                   <>
                   <ServicesSection
                       styles={this.props.data.cssfile}
                       data={this.props.data}
                       state={this.state}
                     />
                   </>}
                  {/* Services Section end ==> */}
       
                  <div className={this.props.data.cssfile.login_container}>
                    {/* Support Section starts ==> */}
                    {this.props.data?.isSupportSection && 
                     <>
                     <SupportSection
                         styles={this.props.data.cssfile}
                         data={this.props.data}
                         state={this.state}
                       />
                     </>}
                    {/* Support Section end ==> */}

                    {/* Language Section starts ==> */}
                    {this.props.data?.isShowLoginsection &&
                    <>
                    <LanguageSection
                        styles={this.props.data.cssfile}
                        data={this.props.data}
                        state={this.state}
                        openLanguageModal={this.openLanguageModal}
                        closeTranslationModal={this.closeTranslationModal}
                      />
                    </>}
                    {/* Language Section end ==> */}
                    
                    {/* Login Section starts ==> */}
                  
                    {this.props.data?.isShowLoginsection &&
                    <>
                    <LoginSection
                        styles={this.props.data.cssfile}
                        data={this.props.data}
                        state={this.state}
                        onClickArrow={this.onClickArrow}
                        onMouseLeave={this.onMouseLeave}
                        onClickProfile={this.onClickProfile}
                        onClickLogout={this.onClickLogout}
                        
                      />
                    </>}
                   
                    {/* Login Section starts ==> */}
                    
                    {/* Manage Booking Section starts ==> */}
                    {this.props.data?.isManageBokkingSection &&
                    <div
                      className={this.props.data.cssfile.afterlogin}
                     
                      onClick={() => {
                        this.props.data?.handleManageBooking(true);
                      }}
                    >
                    <h2> Manage Booking</h2>

                
                    </div>}
                     {/* Manage Booking Section end ==> */}
                    
                  </div>

                </div>
              </div>
            </div>
          </header>

        {/* Header for HomePage ends */}

      </>
    );
  }
}

export default Header8;
