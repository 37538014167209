import React, { Component } from "react";
import ModalPopupComponent from "../../ModalPopupComponent/theme8/ModalPopupComponent";
import { managebooking } from "../../../lib/managebooking";
import { emailReg ,alphanumericSpecialChars} from "../../../lib/utils/regex_cheat_sheet";
class ManageBooking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPopup: true,
      searchBookingTxt: "",
      originalBookingDetails: [],
      activeTab: "",
      statusTab: "",
      isFirstLoaded: false,
      path: "",
      isSortByServiceListVisible: false,
      name: "",
      email: "",
      orderNo: "",
      orderNoError: false,
      emailIDError: false,
      emailIDErrorRecheck: false,
      isData: false,
      btnClicked: false,
    };
  }

  onClosePopup = () => {
    this.props.data?.handleManageBooking(false);
  };

  searchBookingChnageHandlerId = (e) => {

    let regexNum = alphanumericSpecialChars.test(e.target.value)
    if(regexNum){
    this.setState((prevState) => {
      return {
        ...prevState,
        orderNo: e.target.value,
      };
    });}
    
  };

  searchBookingChnageHandleremailId = (e) => {
    let regexNum = alphanumericSpecialChars.test(e.target.value)
    
    if(regexNum){
    this.setState((prevState) => {
      return {
        ...prevState,
        email: e.target.value,
      };
    });
  }

  };

  filterHandler = (type) => {
   
    const { orderNo, email } = this.state;

    // Default state for error flags
    let orderNoError = false;
    let emailIDError = false;
    let emailIDErrorRecheck = false;
    let validationSuccess = true; // Assume validation passes initially

    // Check if orderNo or email is empty
    if (!orderNo || !email) {
      orderNoError = true;
      emailIDError = true;
      validationSuccess = false; // Validation fails if fields are empty
    }
    if (email && !email.match(emailReg)) {
      // Only check email format if it's not empty
      emailIDErrorRecheck = true;
      validationSuccess = false; // Validation fails if email is not valid
    }
    if (email.match(emailReg) && orderNo && email) {
      validationSuccess = true;
    }
    // Update state in a single setState call
    this.setState({
      orderNoError,
      emailIDError,
      emailIDErrorRecheck,
      validationSuccess, // Add validation success flag to state
    });

    if (validationSuccess) {
      // Proceed with form submission or other actions
    
      this.setState((prevState) => {
        return {
          ...prevState,
          btnClicked: true,
        };
      });
      this.bookingDetailsFunc(orderNo, email);
    } else {
      console.log("Validation failed");
    }
  };

  bookingDetailsFunc(orderNo, email) {
    let data = {
      site_id: this.props?.data?.siteData?.site?.id,
      order_no: orderNo,
      search_text: email,
      isapiout:false,
      is_orgfilter:false,
    };

    try {
      managebooking(data).then((res) => {
       
        if (res?.data?.status) {
          let queryString = `orderNo=${this.state.orderNo}&email=${this.state.email}`;
          window.location = "/bookingOverview?" + queryString;
        } else {
          this.setState((prevState) => {
            return {
              ...prevState,
              isData: true,
              btnClicked: false,
            };
          });
        }
      });
    } catch (error) {
      console.error("Error fetching booking data:", error);

      setState((prevState) => {
        return {
          ...prevState,
          isData: true,
        };
      });
    }
  }

  render() {
    const styles = this.props.data?.styles;
    
    return (
      <div className={styles.manageBooking_modal_container}>
        <ModalPopupComponent
          isMobile={this.props.data.isMobile}
          isPopup={this.props.data.manageBokingPopup}
          onClosePopup={this.onClosePopup}
          modalCss={this.props.data.modalCss}
          isManageBooking={true}
          showCrossButton={this.props.data.isMobile ? false : true}
        >
          <div>
            {this.props.data?.isMobile && (
              <div className={styles.header_wrapper}>
                <div onClick={this.onClosePopup} className={styles.header_back_arrow}>
                <svg xmlns="http://www.w3.org/2000/svg" width="9" height="17" viewBox="0 0 9 17" fill="none">
                  <path d="M7.7191 0.996325L0.219939 8.26868C0.150215 8.33622 0.0949021 8.41643 0.0571624 8.50472C0.0194236 8.593 9.65387e-08 8.68763 9.5399e-08 8.7832C9.42594e-08 8.87877 0.0194236 8.97341 0.0571624 9.06169C0.0949021 9.14998 0.150215 9.23018 0.219939 9.29772L7.7191 16.5701C7.85982 16.7065 8.05067 16.7832 8.24967 16.7832C8.44867 16.7832 8.63952 16.7065 8.78023 16.5701C8.92095 16.4336 9 16.2485 9 16.0556C9 15.8626 8.92095 15.6775 8.78023 15.541L1.8107 8.7832L8.78023 2.02536C8.84991 1.9578 8.90518 1.87758 8.94288 1.7893C8.98059 1.70102 9 1.6064 9 1.51084C9 1.41529 8.98059 1.32067 8.94288 1.23239C8.90518 1.14411 8.84991 1.06389 8.78023 0.996325C8.71056 0.928757 8.62784 0.875159 8.53681 0.838593C8.44577 0.802025 8.3482 0.783203 8.24967 0.783203C8.15113 0.783203 8.05356 0.802025 7.96253 0.838593C7.87149 0.875159 7.78878 0.928757 7.7191 0.996325Z" fill="#333333"/>
                </svg>
                </div>
                <h4 className={styles.header_title}> Manage Booking </h4>
              </div>
            )}
          </div>
          <div className={styles.manage_booking_container}>
            <div className={styles.manage_booking_heading}>
              <h4 className={styles.manage_book_heading}> Manage Booking </h4>
              <p className={styles.manage_book_label}>
                {" "}
                Retrieve your booking using order ID and email address{" "}
              </p>
            </div>
            <div className={styles.form_flex}>
              <div className={styles.Filt_inp_Wrap}>
                <div className={styles.Filt_inp_bx}>
                  <div className={styles.label_input}>
                    <label className={styles.filt_label}>Booking ID</label>
                    <input
                      type="text"
                      className={styles.filt_inp}
                      placeholder="Enter booking ID"
                      value={this.state?.orderNo}
                      onChange={this.searchBookingChnageHandlerId}
                    />
                    <div className={styles.error_message}>
                      <span>
                        {" "}
                        {this.state?.orderNoError && this.state?.orderNo == ""
                          ? "Please enter valid booking ID"
                          : ""}{" "}
                        {/* {this.state?.isData ? "Please Enter Valid Data" : ""}{" "} */}
                      </span>
                    </div>
                  </div>
                </div>
                <div className={styles.Filt_inp_bx}>
                  <div className={styles.label_input}>
                    <label className={styles.filt_label}>Email address</label>
                    <input
                      type="text"
                      className={styles.filt_inp}
                      placeholder="Enter email address"
                      value={this.state.email}
                      onChange={this.searchBookingChnageHandleremailId}
                    />
                    <div className={styles.error_message}>
                      <span>
                        {" "}
                        {this.state?.emailIDError && this.state.email == ""
                          ? "Please enter email address"
                          : ""}{" "}
                      </span>
                      <span>
                        {" "}
                        {this.state?.emailIDErrorRecheck &&
                        this.state.email != ""
                          ? "Please enter valid email address"
                          : ""}{" "}
                        {this.state?.isData ? "Please Enter Valid Data" : ""}{" "}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              {((this.state?.orderNoError && this.state?.emailIDError) || this.state?.isData) && (
                <div className={styles.question_suggest}>
                  <h4 className={styles.question_suggest_details}>
                    If you have any questions or suggestions, you can call us at{" "}
                    <br />
                    <a href={`tel:${this.props.data?.siteData?.site?.support_number}`}>
                      ({this.props.data?.siteData?.site?.support_number})
                    </a> {" "}
                    or email us at {" "}
                    <a href={`mailto:${this.props.data?.siteData?.site?.support_email}`}>
                      ({this.props.data?.siteData?.site?.support_email})
                    </a>
                  
                  </h4>
                </div>
              )}
             
              <div
                className={`${styles.filt_btn_bx}
              ${
                this.state?.orderNoError && this.state?.emailIDError
                  ? styles.space_remove
                  : ""
              }`}
              >
                <button
                  onClick={() => this.filterHandler("all")}
                 
                  className={
                    this.state?.btnClicked
                      ? `${styles.Filt_apply} loading`
                      : styles.Filt_apply
                  }
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </ModalPopupComponent>
      </div>
    );
  }
}

export default ManageBooking;
