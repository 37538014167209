import React, { Component, Fragment } from "react";
import commonFunction from "../../../lib/utils/common";
import static_config from "../../../lib/utils/enum.json";
class Footer8 extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const styles = this.props.data.cssfile;

    return (
      <>
        <div className={`${styles.footer} ${window.location.pathname == "/" && this.props.data.isMobile ? styles.footerHome : ""}`}>
          <div className={styles.footerMain_container}>
            <div className={styles.footerContainer}>
              <nav className={styles.footerNav}>
              {!commonFunction.isValueEmpty(this.props) &&
                  !commonFunction.isValueEmpty(this.props.data) &&
                  !commonFunction.isValueEmpty(this.props?.data?.footermenu) &&
                  !commonFunction.isValueEmpty(
                    this.props?.data?.footermenu?.menu
                  ) &&
                  this.props?.data?.footermenu?.menu.map(
                    (fLink, index) => (
                      <Fragment key={"menu" + index}>
                        {fLink.active == "active" ? (
                          <a href={fLink.url} className={styles.footerNavLink} tabIndex="0" target="_blank" rel="noopener noreferrer">
                            {fLink.text}
                          </a>
                        ) : (
                          ""
                        )}
                      </Fragment>
                    )
                  )}
              </nav>
              <div className={styles.footerBrand}>
                <span className={styles.footerBrandText}>
                  Operated and managed by
                </span>
               <a href={static_config.vernost_url} target="_blank">
               <img
                  loading="lazy"
                  src="/images/vernost_logoUpdated.png"
                  className={styles.footerBrandLogo}
                  alt="Akasa Air operator logo"
                />
               </a>
              </div>
            </div>
            <div className={styles.footerBottom}>
              <div className={styles.footerCopyright}>
                {this.props?.data?.footermenu?.text}
              </div>
              <div className={styles.footerSocial}>
                {!commonFunction.isValueEmpty(this.props) &&
                  !commonFunction.isValueEmpty(this.props.data) &&
                  !commonFunction.isValueEmpty(this.props?.data?.footermenu) &&
                  !commonFunction.isValueEmpty(
                    this.props?.data?.footermenu?.socialmedia
                  ) &&
                  this.props?.data?.footermenu?.socialmedia.map(
                    (sociallink, index) => (
                      <Fragment key={"socialmedia" + index}>
                        {sociallink.active == "active" ? (
                          <a
                            title={sociallink.text}
                            key={sociallink.text}
                            href={
                              localStorage.getItem("appType") == "mobileapp"
                                ? "/mobileAppLink?page=" +
                                  sociallink.text +
                                  "&value=" +
                                  sociallink.url
                                : sociallink.url
                            }
                            className=""
                          >
                            <img
                              src={`${
                                "/shared/api/media/" +
                                this.props?.data?.siteData?.site.id +
                                "/" +
                                sociallink?.icon
                              }`}
                              alt={sociallink.text}
                            />
                          </a>
                        ) : (
                          ""
                        )}
                      </Fragment>
                    )
                  )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Footer8;
